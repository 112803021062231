import React from 'react';
import SidiPostListSub from './SidePostListSub';

const PostList = ({ posts }) => {
    return (
        <div className='mb-5 content-main'>
            <div>
                <h4 className='mb-4'>熱門文章</h4>
            </div>

            <SidiPostListSub />

            <SidiPostListSub />

            <SidiPostListSub />
        </div>
    );
};

export default PostList;